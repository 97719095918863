import React, {useState} from "react";
import {Alert} from "@material-ui/lab";
import {Snackbar as SnackbarUi} from "@material-ui/core";
import {Color} from "@material-ui/lab/Alert";

export type SnackbarState = {
    message: string;
    severity: Color
} | null;

let update: (state: SnackbarState) => void;

export function showSnackbar(state: SnackbarState){
    update && update(state);
}

export const Snackbar = () => {
    const [state, setState] = useState<SnackbarState>(null);
    update = (newState: SnackbarState) => {
        setState(newState);
    }

    return (
        state &&
        <SnackbarUi open={!!state} message={state.message} autoHideDuration={2000} onClose={() => setState(null)}>
            <Alert severity={state!.severity}>
                {state!.message}
            </Alert>
        </SnackbarUi>
    )
}
