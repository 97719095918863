import Cookies from 'js-cookie'

export interface AccessToken{
    access: string;
}

export interface JwtToken extends AccessToken{
    refresh: string;
}

export interface StoredToken{
    token: JwtToken;
    creationTime: number;
}

const TOKEN_KEY = 'auth-token';

function getJwt(): JwtToken | undefined {
    const storedToken = getStoredToken();
    if(!storedToken){
        return ;
    }
    const {token, creationTime} = storedToken;
    if(isTokenExpired(creationTime!)){
        deleteToken();
        return ;
    }
    return token;
}

export function getStoredToken(): StoredToken | undefined {
    const tokenWithExpiry = Cookies.get('auth-token');
    if(!tokenWithExpiry){
        return ;
    }
    return JSON.parse(tokenWithExpiry) as StoredToken;
}

export function deleteToken(){
    Cookies.remove(TOKEN_KEY);
}

export function setToken(token: JwtToken) {
    Cookies.set(TOKEN_KEY, JSON.stringify({token, creationTime: new Date().getTime()}), {expires: new Date().setFullYear(new Date().getFullYear() + 1)});
}

export function isTokenExpired(timeMillis: number){
    const now = new Date().getTime();
    return now - timeMillis > 1000 * 60 * 60 * 12;
}

export function getAccessToken(): string {
    return getJwt()!?.access;
}
