import {useContext} from "react";
import {DataContext} from "./data";
import {loadInitialData} from "../api/api";
import {useSocket} from "../websocket/websocket";
import {showSnackbar, SnackbarState} from "../Components/Snackbar/Snackbar";
import {Category, DeleteItemMsg, Product, UpsertItemMsg} from "../models";

const productAddedMessage = (product: Product): SnackbarState => ({
    message: `New product added: ${product.german_name}`,
    severity: 'info'
});

const productUpdatedMsg = (product: Product): SnackbarState => ({
    message: `Product updated: ${product.german_name}`,
    severity: 'success'
});

const productDeletedMessage = (product: Product): SnackbarState => ({
    message: `Product '${product.german_name}' deleted`,
    severity: 'warning'
});


const useData = () => {
    const [state, setState] = useContext(DataContext);

    useSocket({
        onUpsertItem({
                         category_id,
                         category_name,
                         product_id,
                         product_name,
                         quantity,
                         product_url,
                         product_deliverer,
                         priorities,
                         price,
                     }: UpsertItemMsg) {
            setState((oldState) => {
                const newState = {...oldState, list: [...oldState.list]};
                let existingCategoryIndex = newState.list.findIndex((c) => c.category_id === category_id);
                const newProduct: Product = {
                    id: product_id,
                    german_name: product_name,
                    quantity,
                    url: product_url,
                    deliverer__name: product_deliverer,
                    priorities,
                    price,
                };
                if (existingCategoryIndex > -1) {
                    newState.list[existingCategoryIndex] = {
                        ...newState.list[existingCategoryIndex],
                        category_name,
                    };
                    const products = newState.list[existingCategoryIndex].products;
                    const existingProductIndex = products.findIndex((p) => p.id === product_id);
                    if (existingProductIndex > -1) {
                        products[existingProductIndex] = {
                            ...products[existingProductIndex], ...newProduct,
                        }
                        showSnackbar(productUpdatedMsg(newProduct));
                    } else {
                        products.push(newProduct);
                        showSnackbar(productAddedMessage(newProduct));
                    }
                } else {
                    newState.list.push({category_id, category_name, products: [newProduct]});
                    showSnackbar(productAddedMessage(newProduct));
                }
                return newState;
            })
        },
        onDeleteItem(itemMsg: DeleteItemMsg) {
            setState((oldState) => {
                return {
                    ...oldState,
                    list: deleteProduct(itemMsg.product_id, [...oldState.list])
                }
            });
        }
    });

    function loadData() {
        setState((oldState) => ({...oldState, listLoading: true}))
        loadInitialData().then((data) => setState((oldState) => ({
            ...oldState,
            listLoading: false,
            list: Array.isArray(data) ? data : []
        })))
    }

    return {
        ...state,
        loadData,
    }
}

function deleteProduct(id: string, categories: Category[]): Category[] {
    for (const category of categories) {
        const product = category.products.find((product) => product.id === id);
        if (product) {
            category.products = category.products.filter(product => product.id !== id);
            showSnackbar(productDeletedMessage(product));
            return categories;
        }
    }
    return categories;
}

export default useData;
