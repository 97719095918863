import {getCsrfToken} from "../utils";
import {getAccessToken} from "../auth/token";
import {Category} from "../models";

function getHeaders(){
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRFToken': getCsrfToken(),
        'Authorization': `Bearer ${getAccessToken()}`
    }
}

export function loadInitialData(): Promise<Category[]> {
    return fetch('/api/get_orders', {
        headers: getHeaders()
    }).then(res => res.json());
}

export function logIn(userName: string, password: string){
    const csrf = getCsrfToken();
    return fetch('/api/token/',
        {
            credentials: 'include',
            mode: 'same-origin',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': csrf
            },
            body: JSON.stringify({csrfmiddlewaretoken: csrf, username: userName, password})
        }
    )
}

export function refreshToken(refresh: string){
    const csrf = getCsrfToken();
    return fetch('/api/token/refresh/', {method: 'POST', headers: getHeaders(), body: JSON.stringify({csrfmiddlewaretoken: csrf, refresh})})
}
