import React from 'react';
import {AuthState} from "../../auth/auth";

export function TopNav(props: AuthState & {logout: () => void}) {
    return (
        <nav className="app-main-navbar navbar navbar-expand-lg navbar-dark bg-dark mb-8">
            <div className="d-flex justify-content-between w-100">
                <div className="navbar-brand text-white">
                    <i className="fas fa-shopping-cart pr-2"></i>
                    Lista zamówień
                </div>
                <div className="w-100 text-right">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#myNavbar7">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
            </div>
            <div className="collapse navbar-collapse flex-grow-1 text-right" id="myNavbar7">
                <ul className="navbar-nav ml-auto flex-nowrap">
                    {
                        props.isLoggedIn ?
                            <React.Fragment>
                                <li className="nav-item d-flex align-items-center text-white">
                                    <i className="far fa-user-circle mr-2"/> {props.userName}
                                </li>
                                <li className="nav-item cursor-pointer" onClick={props.logout}>
                                    <i className="nav-link px-3">Logout</i>
                                </li>
                            </React.Fragment>
                            :
                            <li className="nav-item">
                                <i className="nav-link px-3">Login</i>
                            </li>
                    }
                </ul>
            </div>
        </nav>
    );
}
