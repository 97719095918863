import React, {ChangeEvent, createRef, useState} from 'react';
import useAuth from "../../auth/useAuth";

export interface LoginState{
    username: string;
    password: string;
}

const initialState = {username: '', password: ''};

export function Login(){
    const [ state, setState ] = useState<LoginState>(initialState);

    const formRef = createRef<HTMLFormElement>();
    const credentialsErrorRef = createRef<HTMLSpanElement>();
    const buttonRef = createRef<HTMLButtonElement>();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const {logIn} = useAuth();

    const handleLogin = () => {
        if(formRef.current?.checkValidity()){
            buttonRef.current!.disabled = true;
            logIn(state.username, state.password).catch(() => {
                credentialsErrorRef.current!.hidden = false;
                setTimeout(() => {
                    credentialsErrorRef.current!.hidden = true;
                    buttonRef.current!.disabled = false;
                    setState(initialState);
                }, 1000)
            });
        }else {
            formRef.current?.reportValidity();
        }
    }

    return (
        <div className="mx-auto py-5" style={{width: '200px'}}>
            <h2>Log In</h2>
            <form ref={formRef} onSubmit={handleLogin}>
                <div className="form-group">
                    <label className="text-info">Username<sup>*</sup></label>
                    <input className="form-control" name="username" value={state.username} onChange={handleChange} required/>
                </div>
                <div className="form-group">
                    <label className="text-info">Password<sup>*</sup></label>
                    <input className="form-control" name="password" type="password" value={state.password} onChange={handleChange} required/>
                </div>
                <button className="btn btn-outline-primary w-100" type="submit" ref={buttonRef} onClick={handleLogin}>Log In</button>
                <span ref={credentialsErrorRef} className="text-danger" hidden>Wrong credentials</span>
            </form>
        </div>
    );
}
