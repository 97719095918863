import {useContext} from "react";
import {AuthContext} from "./auth";
import {AccessToken, deleteToken, getStoredToken, setToken} from "./token";
import {refreshToken, logIn as postLogIn} from "../api/api";

let refreshingJob: any;
function startRefreshing(){
    const token = getStoredToken();
    if(!token){
        throw new Error('Trying to refresh non existing token');
    }
    if(refreshingJob){
        clearInterval(refreshingJob);
    }
    // Setting interval instead of timeout, because milliseconds passed to setInterval/setTimeout depends on external factors
    // (when tab is inactive, time passes slower)
    refreshingJob = setInterval(() => {
        if(needsRefresh(token.creationTime)){
            clearInterval(refreshingJob);
            refreshToken(token.token.refresh).then((res) => {
                if(res.ok){
                    return res.json();
                }else {
                    throw new Error();
                }
            }).then((accessToken: AccessToken) => {
                setToken({ refresh: token.token.refresh, access: accessToken.access });
            }).finally(() => {
                startRefreshing();
            });
        }
    }, 1000 * 10);
}

function needsRefresh(creationTime: number){
    const now = new Date().getTime();
    return now - creationTime > 1000 * 60 * 60;
}

const useAuth = () => {
    const [state, setState] = useContext(AuthContext);

    if(state.isLoggedIn){
        startRefreshing();
    }

    function logIn(userName: string, password: string) {
        return postLogIn(userName, password).then((res) => {
            if (res.status === 401) {
                throw new Error('UNAUTHORIZED');
            }
            return res.json();
        })
        .then((res) => {
            setToken(res);
            startRefreshing();
            setState({isLoggedIn: true, userName});
        })
    }

    function logOut() {
        deleteToken();
        setState({isLoggedIn: false, userName: null})
    }

    return {
        ...state,
        logIn,
        logOut
    }
}

export default useAuth;

