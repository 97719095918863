export interface Category {
    category_id: number;
    category_name: string;
    products: Product[];
}

export interface Product {
    id: string;
    german_name: string;
    quantity: number;
    url: string;
    deliverer__name: string;
    priorities: number[];
    price: string;
}

export enum MessageTypes {
    UPDATE_ITEM = 'update_item',
    DELETE_ITEM = 'delete_item'
}

export interface UpsertItemMsg {
    type: MessageTypes.UPDATE_ITEM,
    product_id: string;
    quantity: number;
    product_name: string;
    product_url: string;
    product_deliverer: string;
    priorities: number[];
    category_id: number;
    category_name: string;
    price: string;
}

export interface DeleteItemMsg {
    type: MessageTypes.DELETE_ITEM,
    product_id: string;
}

export type Message = UpsertItemMsg | DeleteItemMsg;
