import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {AuthProvider} from "./auth/auth";
import {DataProvider} from "./data/data";

ReactDOM.render(
    <AuthProvider>
        <DataProvider>
            <App/>
        </DataProvider>
    </AuthProvider>,
    document.getElementById('root')
);
