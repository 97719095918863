import {useEffect} from "react";
import {DeleteItemMsg, Message, MessageTypes, UpsertItemMsg} from "../models";


export interface WebsocketListener {
    onUpsertItem(item: UpsertItemMsg): void;
    onDeleteItem(item: DeleteItemMsg): void;
}

let socket: WebSocket | null;


function getSocket() {
    if (!socket) {
        socket = new WebSocket(process.env.REACT_APP_WS_URL!);
    }
    return socket;
}

export const useSocket = (listener: WebsocketListener) => {
    const onMessage = (msg: MessageEvent) => {
        const message: Message = JSON.parse(msg.data);
        switch (message.type) {
            case MessageTypes.UPDATE_ITEM:
                listener.onUpsertItem(message);
                break;
            case MessageTypes.DELETE_ITEM:{
                listener.onDeleteItem(message);
                break;
            }
            default:
                console.warn('Unknown message: ', message);
        }
    };

    const wSocket = getSocket();

    const onClose = (event: CloseEvent) => {
        socket = null;
        addListeners(getSocket());
    };

    function addListeners(socket: WebSocket){
        socket.addEventListener('message', onMessage);
        socket.addEventListener('close', onClose);
    }

    useEffect(() => {
        addListeners(getSocket());
        return () => {
            wSocket.removeEventListener('message', onMessage);
            wSocket.removeEventListener('close', onClose)
        }
    })
}

export function updateIem(id: string, quantity: number) {
    const socket = getSocket();
    const send = () => socket.send(JSON.stringify({
        type: 'update_item',
        id,
        quantity
    }))

    if (socket.OPEN && !socket.CONNECTING) {
        send();
    } else {
        const onOpen = () => {
            send();
            socket.removeEventListener('open', onOpen);
        }
        socket.addEventListener('open', onOpen);
    }
}
