import React, {createRef} from "react";
import Select from "react-select";
import {Category} from "../../models";

export function CategoriesFilter(props: { categories: Category[], activeCategories: number[], categoryChanged: (categories: number[]) => void }) {
    const selectRef = createRef<any>();

    const categories = props.categories.filter(category => !!category.products.length);
    const activeCategories = props.activeCategories.map(id => categories.find(({category_id}) => category_id === id)).filter(category => !!category);

    setTimeout(() => {
        if (!selectRef.current) {
            return;
        }
        const value = selectRef.current.state.value;
        if (!props.activeCategories.length && value?.length) {
            selectRef.current.select.clearValue();
        }
    });

    const getOptionLabel = (category: Category) => {
        const c = categories.find(({category_id}) => category_id === category.category_id);
        return c ? `${c.category_name} (${c.products.length})` : '';
    }

    return (
        <Select ref={selectRef}
                placeholder={'Filter by category'}
                options={categories}
                isMulti
                defaultValue={activeCategories as Category[]}
                onChange={(event: any) => props.categoryChanged(event ? event.map(({category_id}: Category) => category_id) : [])}
                getOptionLabel={getOptionLabel}
                getOptionValue={(opt: Category) => opt.category_id as any}
        />
    )
}

export function DeliverersFilter(props: { deliverers: string[], activeDeliverers: string[], delivererChanged: (deliverers: string[]) => void }) {
    return (
        <Select placeholder={'Filter by deliverer'}
                options={props.deliverers as any}
                isMulti
                defaultValue={props.activeDeliverers}
                onChange={(event: any) => props.delivererChanged(event || [])}
                getOptionLabel={(p: any) => p}
                getOptionValue={(p: any) => p}
        />
    )
}

export function PrioritiesFilter(props: { activePriorities: number[], prioritiesChanged: (priorities: number[]) => void }) {
    const allPriorities = [0, 1, 2, 3, 4];

    const priorityChanged = (priority: number, isChecked: boolean) => {
        let priorities: number[];
        if (isChecked) {
            priorities = [...props.activePriorities, priority];
        } else {
            priorities = props.activePriorities.filter(p => p !== priority);
        }
        props.prioritiesChanged(priorities)
    }

    return (
        <div className="d-flex justify-content-between">
            {
                allPriorities.map(priority => <input key={priority} className={"priority-input priority-" + priority}
                                                     type="checkbox"
                                                     checked={props.activePriorities.includes(priority)}
                                                     onChange={event => priorityChanged(priority, event.target.checked)}/>)
            }
        </div>
    )
}
