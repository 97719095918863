import React, {useEffect, useState} from 'react';
import useData from "../../data/useData";
import {Loader} from "../Loader/Loader";
import {identity} from "../../utils";
import {CategoriesFilter, DeliverersFilter, PrioritiesFilter} from "./Filters";
import {ProductRow} from "./ProductRow";
import {Category, Product} from "../../models";


function CategoryWithProductsDisplay(props: { category: Category }) {
    return (
        <div>
            <h3 className="category-title">{props.category.category_name}</h3>
            <ul className="list-unstyled">
                {props.category.products.map((p) => (<ProductRow key={p.id} product={p}/>))}
            </ul>
        </div>
    )
}

function ProductsList(props: { productsByCategory: Category[] }) {
  const sum = props.productsByCategory
    .flatMap((category) => {
      return category.products;
    })
    .map((product) => {
      const price = Number(product.price.replace(",", ".") || 0);
      return product.quantity * price;
    })
    .reduce((prev, current) => prev + current, 0)
    .toFixed(2);
  return (
    <div>
      {props.productsByCategory.map((p) => (
        <CategoryWithProductsDisplay key={p.category_id} category={p} />
      ))}
      {
        <div className="row align-items-center product-row summary">
          <div className="col-sm-12 col-lg-7">Sum of all products:</div>

          <div className="col-sm-12 col-lg-5">
            <div className="row">{`${sum}€`}</div>
          </div>
          <span></span>
        </div>
      }
    </div>
  );
}


export function ListPage() {
    const {loadData, listLoading, list} = useData();

    useEffect(() => {
        loadData()
    }, []);

    let [{
        categoriesFilter,
        deliverersFilter,
        prioritiesFilter
    }, setState] = useState<{ categoriesFilter: number[], deliverersFilter: string[], prioritiesFilter: number[] }>({
        categoriesFilter: [],
        deliverersFilter: [],
        prioritiesFilter: [0, 1, 2, 3, 4]
    });

    categoriesFilter = categoriesFilter.filter((id) => {
        const category = list.find(({category_id}) => category_id === id);
        return category && category.products.length;
    });

    const handleCategoryChange = (categoriesFilter: number[]) => {
        setState((oldState) => ({...oldState, categoriesFilter}))
    }

    const handleDelivererChange = (deliverersFilter: string[]) => {
        setState((oldState) => ({...oldState, deliverersFilter}));
    }

    const handlePriorityChange = (prioritiesFilter: number[]) => {
        setState((oldState) => ({...oldState, prioritiesFilter}));
    }

    const deliverers = Array.from(new Set(list.flatMap(({products}: Category) => products).flatMap(({deliverer__name}: Product) => deliverer__name)));

    const filteredList = (): Category[] => {
        // TODO: refactor filtering to use single loop and add counts near all filters (like in categories)
        const productsWithDeliverer = (products: Product[]) => products.filter(({deliverer__name}) => deliverersFilter.includes(deliverer__name));
        const productsWithPriority = (products: Product[]) => products.filter(({
                                                                                   priorities
                                                                               }) => !priorities.length || priorities.some((p, i) => p > 0 && prioritiesFilter.includes(i)));
        const filterByCategories = categoriesFilter.length ? ((categories: Category[]) => categories.filter(({category_id}) => categoriesFilter.includes(category_id))) : identity;
        const filterByDeliverers = deliverersFilter.length ? ((categories: Category[]) => categories.map((category) => ({
            ...category,
            products: productsWithDeliverer(category.products)
        }))) : identity;
        const filterByPriorities = (categories: Category[]) => categories.map((category) => ({
            ...category,
            products: productsWithPriority(category.products)
        }));

        return filterByPriorities(filterByDeliverers(filterByCategories(list))).filter(({products}) => products.length);
    }


    return (
        <div className="app-body">
            {
                listLoading ?
                    <div className="loader-wrapper">
                        <Loader/>
                    </div>
                    :
                    (
                        <section className="container">
                            <div className="row justify-content-center">
                                <div className="col">
                                    <div className="filters-wrapper">
                                        <div className="padded-v-1rem">
                                            <CategoriesFilter categories={list}
                                                              activeCategories={categoriesFilter}
                                                              categoryChanged={handleCategoryChange}/>
                                        </div>

                                        <div className="padded-v-1rem">
                                            <DeliverersFilter deliverers={deliverers}
                                                              activeDeliverers={deliverersFilter}
                                                              delivererChanged={handleDelivererChange}/>
                                        </div>
                                        <div className="padded-v-1rem">
                                            <PrioritiesFilter activePriorities={prioritiesFilter}
                                                              prioritiesChanged={handlePriorityChange}/>
                                        </div>
                                    </div>
                                    <ProductsList productsByCategory={filteredList()}/>
                                </div>
                            </div>
                        </section>
                    )
            }
        </div>
    );
}
