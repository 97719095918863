import React, {ChangeEvent, useState} from "react";
import {updateIem} from "../../websocket/websocket";
import {Product} from "../../models";

export function ProductRow(props: { product: Product }) {
    const [state, setState] = useState<number | ''>('');
    const [isPopupVisible, setPopupVisible] = useState<boolean>(false);
    const {product} = props;

    const handleClick = () => {
        updateIem(product.id, Number(state) || product.quantity);
        setState('');
    }

    const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
        const quantity = Number(event.target.value);
        if (quantity <= product.quantity) {
            setState(quantity !== 0 ? quantity : '');
        } else {
            setState(product.quantity);
        }
    }

    let priority = props.product.priorities.findIndex((amount) => amount > 0);

    let priorityClass: string = 'priority-' + (priority > -1 ? priority : 'NAN');

    const closeImage = () => {
        setPopupVisible(false);
        document.removeEventListener('click', closeImage);
    }

    const openImage = () => {
        if(!product.url){
            return;
        }
        setPopupVisible(true);
        setTimeout(() => {
            document.addEventListener('click', closeImage);
        }, 500)
    }

    return (
        <li className={"row align-items-center product-row " + priorityClass}>
            <div className="col-sm-12 col-lg-7">
                <span className="text-nowrap">{product.quantity}&nbsp; &#10005; &nbsp;</span>
                <a onClick={() => {openImage()}} href="#0">{product.german_name}</a>
            </div>
            <div className="col-sm-12 col-lg-5 py-sm-2">
                <div className="row">
                    <div className="col-7 d-flex align-items-center">
                        <span className="padded-h-1rem text-sm align-middle font-italic">
                            {
                                props.product.priorities.map((amount, index) => amount > 0
                                    ?
                                    <strong key={index} className={"padded-h-1rem priority-text-" + index}>{amount}</strong>
                                    : null)
                            }
                        </span>
                        <small className="padded-h-1rem text-sm align-middle font-italic">
                            {product.deliverer__name}
                        </small>
                        {product.price && (<strong className="padded-h-1rem">{product.price}€</strong>)}
                    </div>
                    <div className="col-5">
                        <div className="row">
                            <div className="col-6">
                                <input className="form-control text-small"
                                       value={state}
                                       onChange={handleQuantityChange}
                                       type="number" min={0} max={product.quantity}/>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-outline-primary bg-white"
                                        onClick={handleClick}>✓
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isPopupVisible && (
                    <div className="image-popup">
                        <img className="image-popup--image" src={product.url} alt={"Product image"}/>
                    </div>
                )
            }
        </li>
    )
}
