import React, {createContext, useState} from "react";
import {getAccessToken} from "./token";

export interface AuthState{
    isLoggedIn: boolean;
    userName: string | null;
}

const InitialState: AuthState = {isLoggedIn: !!getAccessToken(), userName: null}

const AuthContext = createContext<[AuthState, React.Dispatch<React.SetStateAction<AuthState>>]>([InitialState, () => {}]);

const AuthProvider = (props: any) => {
    const [state, setState] = useState<AuthState>(InitialState);
    return (
        <AuthContext.Provider value={[state, setState]}>
            {props.children}
        </AuthContext.Provider>
    )
}

export {AuthContext, AuthProvider};
