import React from 'react';
import './App.css';
import {TopNav} from "./Components/TopNav/TopNav";
import {Login} from "./Components/Login/Login";
import {ListPage} from "./Components/ListPage/ListPage";
import useAuth from "./auth/useAuth";
import {Snackbar} from "./Components/Snackbar/Snackbar";

function App() {

    const {isLoggedIn, userName, logOut} = useAuth();

    return (
        <React.Fragment>
            <TopNav isLoggedIn={isLoggedIn} userName={userName} logout={logOut}/>
            {isLoggedIn ? <ListPage/> : <Login/>}
            <Snackbar/>
        </React.Fragment>
    )
}

export default App;
