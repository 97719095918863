import React, {createContext, useState} from "react";
import {Category} from "../models";

export interface DataState{
    list: Category[];
    listLoading: boolean;
}

const InitialState: DataState = {list: [], listLoading: false}

const DataContext = createContext<[DataState, React.Dispatch<React.SetStateAction<DataState>>]>([InitialState, () => {}]);

const DataProvider = (props: any) => {
    const [state, setState] = useState<DataState>(InitialState);
    return (
        <DataContext.Provider value={[state, setState]}>
            {props.children}
        </DataContext.Provider>
    )
}

export {DataContext, DataProvider};
